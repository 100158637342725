import API from './api'
import store from '../store'

export default {
  login (data) {
    return new Promise((resolve, reject) => {
      API.post('login', data).then((response) => {
        if (response && response.data.data.user) {
          response.data.data.user.token = response.data.data.token
          this.setUser(response.data.data.user)
          // console.log(response.data.data);
          if(response.data.data.user.role === 'bar' && response.data.data.user.is_account_onboarding === 0 && response.data.data.account_link){
            window.location = response.data.data.account_link.url
          }
          resolve(response.data.data.user)
        } else {
          resolve(false)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  },
  logout (data) {
    return new Promise((resolve) => {
      API.post('logout', data).then((response) => {
        store.dispatch('reset')
        store.commit('Init/INIT', response.data.data)
        resolve()
      }).catch(error => {
        store.commit('Message/SET_MESSAGE', {
          message: error.response.errors.join('<br>'),
          type: 'warning'
        })
      })
    })
  },
  setUser (user) {
    if (user) {
      store.commit('User/SET_LOGIN_STATUS', true)
    } else {
      store.commit('User/SET_LOGIN_STATUS', false)
    }
    store.commit('User/SET_USER', user)
  },
  validateToken () {
    return new Promise((resolve) => {
      // TO DO reject
      resolve()
    })
  },
  get (url) {
    return new Promise((resolve, reject) => {
      API.get(url).then(response => {
        resolve(response.data)
      }).catch(error => {
        store.commit('Message/SET_MESSAGE', {
          message: error.response.data.errors.join('<br>'),
          type: 'warning'
        })
        reject(error)
      })
    })
  },
  post (url, data) {
    return new Promise((resolve, reject) => {
      API.post(url, data).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
