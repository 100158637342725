<template>
  <div
    class="onboarding-pending w-full cursor-pointer"
    @click="goToOnboarding()"
  >
    Complete your onboarding process to active your bar.
    <img
      src="../assets/images/right-arrow.png"
      class="w-8 ml-3 inline"
      alt=""
    />

    <svg
      v-if="processing"
      class="animate-spin ml-3 h-5 w-5 text-white inline"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: "OnboardingPending",
  data() {
    return {
      processing: false,
    };
  },
  methods: {
    goToOnboarding() {
      if (!this.processing) {
        this.processing = true;
        this.$api.post("bar/create-stripe-account-link").then((response) => {
          window.location = response.data.url;
        });
      }
    },
  },
};
</script>