const publicRoutes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "public" */ '../views/auth/SignIn.vue'),
    meta: {
      onlyGuest: true
    }
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import(/* webpackChunkName: "public" */ '../views/auth/SignUp.vue'),
    meta: {
      onlyGuest: true
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "public" */ '../views/auth/ForgotPassword.vue'),
    meta: {
      onlyGuest: true
    }
  }
  ,
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "public" */ '../views/PrivacyPolicy.vue'),
  }
  ,
  {
    path: '/terms-condition',
    name: 'TermsCondition',
    component: () => import(/* webpackChunkName: "public" */ '../views/TermsCondition.vue'),
  }
]

export default publicRoutes
