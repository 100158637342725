const privateRoutes = [
  {
    path: '/dashboard',
    component: () => import(/* webpackChunkName: "Admin" */ '../admin_views/dashboard/index'),
    name: 'Dashboard',
    meta: {
      requiresAuth: true,
      role: 'admin'
    }
  },
  {
    path: '/bars',
    component: () => import(/* webpackChunkName: "Private" */ '../admin_views/bars/index'),
    meta: {
      requiresAuth: true,
      role: 'admin'
    },
    children: [
      {
        path: 'list',
        name: 'BarList',
        component: () => import(/* webpackChunkName: "Private" */ '../admin_views/bars/list'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        },
      }
    ]
  },
  {
    path: '/users',
    component: () => import(/* webpackChunkName: "Private" */ '../admin_views/users/index'),
    meta: {
      requiresAuth: true,
      role: 'admin'
    },
    children: [
      {
        path: 'list',
        name: 'UserList',
        component: () => import(/* webpackChunkName: "Private" */ '../admin_views/users/list'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        },
      }
    ]
  },
  {
    path: '/categories',
    component: () => import(/* webpackChunkName: "Private" */ '../admin_views/categories/index'),
    meta: {
      requiresAuth: true,
      role: 'admin'
    },
    children: [
      {
        path: 'list',
        name: 'CategoriesList',
        component: () => import(/* webpackChunkName: "Private" */ '../admin_views/categories/list'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        },
      },
      {
        path: 'sub-categories/:id',
        name: 'SubCategories',
        component: () => import(/* webpackChunkName: "Private" */ '../admin_views/categories/sub_categories'),
        meta: {
          requiresAuth: true,
          role: 'bar'
        },
      }
    ]
  },
  {
    path: '/lookup-drinks',
    component: () => import(/* webpackChunkName: "Private" */ '../admin_views/lookup_drinks/index'),
    meta: {
      requiresAuth: true,
      role: 'admin'
    },
    children: [
      {
        path: 'list',
        name: 'LookupDrinksList',
        component: () => import(/* webpackChunkName: "Private" */ '../admin_views/lookup_drinks/list'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        },
      }
    ]
  },
  {
    path: '/reports',
    component: () => import(/* webpackChunkName: "Private" */ '../admin_views/reports/index'),
    meta: {
      requiresAuth: true,
      role: 'admin'
    },
    children: [
      {
        path: 'list',
        name: 'ReportsList',
        component: () => import(/* webpackChunkName: "Private" */ '../admin_views/reports/list'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        },
      }
    ]
  },
  {
    path: '/pages',
    component: () => import(/* webpackChunkName: "Private" */ '../admin_views/pages/index'),
    meta: {
      requiresAuth: true,
      role: 'admin'
    },
    children: [
      {
        path: 'list',
        name: 'PagesList',
        component: () => import(/* webpackChunkName: "Private" */ '../admin_views/pages/list'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        },
      }
    ]
  },
  {
    path: '/settings',
    component: () => import(/* webpackChunkName: "Private" */ '../admin_views/settings/index'),
    meta: {
      requiresAuth: true,
      role: 'admin'
    },
    children: [
      {
        path: 'list',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "Private" */ '../admin_views/settings/list'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        },
      }
    ]
  },
]
export default privateRoutes
