import { createStore } from 'vuex'
import Storage from '@/helper/Storage'
import modules from './modules'

const Vuex = createStore
export default new Vuex({
  modules,
  actions: {
    reset ({ commit }) {
      Object.keys(modules).forEach(moduleName => {
        commit(`${moduleName}/RESET`)
      })
      Storage.Clear()
    }
  }
})
