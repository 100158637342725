<template>
  <div class="select-with-search">
    <slot></slot>
    <div class="arrow" @click="toggleOptions"></div>
    <div
      class="absolute w-full bg-white shadow-xl border-b-2 z-50"
      v-if="showOptions"
    >
      <ul class="suggestions">
        <li
          v-for="(option, index) in filteredOptions"
          :key="index"
          @click="optionClicked(option)"
        >
          {{ option[show] }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectWithSearch",
  props: {
    options: {
      type: Object,
    },
    show: {
      type: String,
      default: "name",
    },
    showOptions: {
      type: Boolean,
      default: false,
    },
    searchString: {
      type: String,
      default: null,
    },
  },
  computed: {
    filteredOptions(){
      if(this.searchString){
        return this.options.filter(item => item[this.show].toLowerCase().includes(this.searchString.toLowerCase()));
      }else{
        return this.options;
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    optionClicked(option) {
      this.$emit("optionClicked", option);
    },
    toggleOptions() {
      this.$emit("toggleOptions");
    },
  },
};
</script>

<style scoped>
.select-with-search {
  position: relative;
}
.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 32px;
  top: 0px;
  right: 20px;
  position: absolute;
  cursor: pointer;
}
.arrow:after {
  background-image: url("../assets/images/arrow-down.png");
  background-repeat: no-repeat;
  content: "";
  width: 16px;
  height: 9px;
  display: inline-block;
}
</style>