import axios from 'axios'
import store from '../store'
import { useToast } from 'vue-toastification'
const toast = useToast()

const API = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
})

const authInterceptor = (config) => {
  config.headers.Accept = 'application/json'
  if (store.getters['User/user'].token) {
    config.headers.Authorization = 'Bearer ' + store.getters['User/user'].token
  }
  return config
}
API.interceptors.request.use(authInterceptor)

API.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error && error.response.status === 401) {
    store.dispatch('reset')
  } 
  
  if (error.response.status === 500 || error.response.status === 422 || error.response.status === 401 || error.response.status === 404) {
    var message = ''
    if (error.response.data.errors) {
      if (error.response.data.errors[0]) {
        message = error.response.data.errors[0]
      } else {
        Object.values(error.response.data.errors).forEach(val => {
          message = message + val[0] + (Object.keys(error.response.data.errors).length > 1 ? ',' : '')
        })
      }
    } else if (error.response.data.message) {
      message = error.response.data.message
    }
    toast.error(message)
    return Promise.reject(error)
  } else {
    return Promise.reject(error)
  }
})

export default API
