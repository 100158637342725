import Storage from '@/helper/Storage'
// import API from '@/api/http'
// import store from '../../store'

const initialState = () => ({
  branches: {},
})

const state = initialState()

const actions = {}

const getters = {
  branches (state) { return state.branches },
}

const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_BRANCHES (state, branches) {
    Storage.Set('branches', branches)
    state.branches = branches
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
