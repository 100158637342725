<template>
    <button :type="type" class="dom-btn relative" :class="{'black':black, 'light':light,'cursor-not-allowed':disabled}" @click="$emit('clicked')" :disabled="disabled">
        <div class="mx-4"><slot></slot> </div>
        <svg v-if="loading" class="absolute animate-spin -ml-1 mr-3 h-5 w-5 text-white right-2 top-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg> 
    </button>
</template>

<script>
export default {
    name: 'Btn',
    props: {
        as: {
            type: String,
            default: 'button',
        },
        type: {
            type: String,
            default: 'button',
        },
        black: {
            type: Boolean,
            default: false,
        },
        light: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    }
}
</script>