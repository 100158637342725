const privateRoutes = [
  {
    path: '/branches',
    component: () => import(/* webpackChunkName: "Private" */ '../views/branches/index'),
    meta: {
      requiresAuth: true,
      role: 'bar'
    },
    children: [
      {
        path: 'list',
        name: 'BrancheList',
        component: () => import(/* webpackChunkName: "Private" */ '../views/branches/list'),
        meta: {
          requiresAuth: true,
          role: 'bar'
        },
      },
      {
        path: 'menu/:id',
        name: 'BrancheMenu',
        component: () => import(/* webpackChunkName: "Private" */ '../views/branches/menu'),
        meta: {
          requiresAuth: true,
          role: 'bar'
        },
      }
    ]
  },
  {
    path: '/purchases',
    name: 'Purchases',
    component: () => import(/* webpackChunkName: "Private" */ '../views/purchases/index'),
    meta: {
      requiresAuth: true,
      role: 'bar'
    },
    children: [
      {
        path: 'list',
        name: 'PurchasesList',
        component: () => import(/* webpackChunkName: "Private" */ '../views/purchases/list'),
        meta: {
          requiresAuth: true,
          role: 'bar'
        },
      }
    ]
  },
  {
    path: '/redemptions',
    name: 'Redemptions',
    component: () => import(/* webpackChunkName: "Private" */ '../views/redemptions/index'),
    meta: {
      requiresAuth: true,
      role: 'bar'
    },
    children: [
      {
        path: 'list',
        name: 'RedemptionsList',
        component: () => import(/* webpackChunkName: "Private" */ '../views/redemptions/list'),
        meta: {
          requiresAuth: true,
          role: 'bar'
        },
      }
    ]
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: () => import(/* webpackChunkName: "Private" */ '../views/transactions/index'),
    meta: {
      requiresAuth: true,
      role: 'bar'
    },
    children: [
      {
        path: 'list',
        name: 'TransactionsList',
        component: () => import(/* webpackChunkName: "Private" */ '../views/transactions/list'),
        meta: {
          requiresAuth: true,
          role: 'bar'
        },
      }
    ]
  },
  {
    path: '/account-details',
    name: 'AccountDetails',
    component: () => import(/* webpackChunkName: "Private" */ '../views/account_details/index'),
    meta: {
      requiresAuth: true,
      role: 'bar'
    }
  },
  {
    path: '/bank-account',
    name: 'BankAccount',
    component: () => import(/* webpackChunkName: "Private" */ '../views/bank_account/index'),
    meta: {
      requiresAuth: true,
      role: 'bar'
    }
  },
  // Events
  {
    path: '/events',
    component: () => import(/* webpackChunkName: "Private" */ '../views/events/index'),
    meta: {
      requiresAuth: true,
      role: 'bar'
    },
    children: [
      {
        path: 'list',
        name: 'EventList',
        component: () => import(/* webpackChunkName: "Private" */ '../views/events/list'),
        meta: {
          requiresAuth: true,
          role: 'bar'
        }
      },
      // {
      //   path: 'menu/:id',
      //   name: 'BrancheMenu',
      //   component: () => import(/* webpackChunkName: "Private" */ '../views/branches/menu'),
      //   meta: {
      //     requiresAuth: true
      //   },
      // }
    ]
  },
]
export default privateRoutes
