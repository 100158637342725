import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from 'vue-toastification'

// CSS
import 'vue-toastification/dist/index.css'
import './assets/style/tailwind.css'
import './assets/style/custom.css'

//Components
import Btn from './components/Btn'
import Img from './components/Img'
import SelectWithSearch from './components/SelectWithSearch'
import { Form, Field, ErrorMessage } from 'vee-validate';

import { useToast } from 'vue-toastification'

const toast = useToast()

// API
import api from './api/http'


const app = createApp(App)
    .use(router)
    .use(Toast,{
        transition: "Vue-Toastification__fade",
        maxToasts: 20,
        newestOnTop: true
    })
    .use(store)

app.component('btn', Btn)
app.component('ig', Img)
app.component('SelectWithSearch', SelectWithSearch)
app.component('Form', Form)
app.component('Field', Field)
app.component('ErrorMessage', ErrorMessage)

app.config.globalProperties.$api = api
app.config.globalProperties.$toast = toast

app.mount('#app')
