<template>
    <img :class="cls" :src="src ? src : defaultSrc" >
</template>

<script>

import defaultSrc from '../assets/images/default.png'

export default {
    name: 'Img',
    props: {
        src: {
            type: String,
            default: defaultSrc,
        },
        cls: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            defaultSrc: defaultSrc
        }
    },
}
</script>