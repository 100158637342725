<template>
    <div class="w-full sticky top-0 z-40 inline-block lg:hidden">
        <div class="w-full h-16 px-6 bg-gray-100 border-b flex items-center justify-between">
            <div class="flex">
                <div class="flex items-center mr-4">
                    <button class="hover:text-blue-500 hover:border-white focus:outline-none navbar-burger" @click="$emit('toggleNavigation')">
                        <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" style="fill: black;">
                        <title>Menu</title>
                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                        </svg>
                    </button>
                </div>
                <div class="relative text-gray-600">
                    <div class="text-xl px-4 truncate font-bold">
                        {{ user.username ? user.username : user.business_name }}
                    </div>
                </div>
            </div>
            <div class="flex items-center relative">
                
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'TopBar',
    computed: {
        ...mapState({
            user: state => state.User.user
        })
    },
    methods: {
        logout () {
            this.$api.logout().then(() => {
                this.$router.push({ name: 'Home' })
            })
        },
    }
}
</script>