<template>
  <div class="w-full overflow-x-hidden">
    <div
      class="flex flex-wrap"
      v-if="user && user.id"
      @toggleNavigation="toggleNavigation"
    >
      <TopBar @toggleNavigation="toggleNavigation"></TopBar>
      <SideBar :showNavigation="showNavigation"></SideBar>
      <div
        :class="{
          'w-full pl-0 min-h-screen lg:pl-64': true,
          'lg:pt-8': user.is_account_onboarding === 1,
        }"
      >
        <OnboardingPending v-if="user.is_account_onboarding === 0" />
        <router-view
          v-slot="{ Component }"
          :class="{
            'px-2 lg:mx-4 overflow-x-auto': true,
            'lg:pt-20': user.is_account_onboarding === 0,
            'lg:pt-10':
              user.is_account_onboarding === 1 || !user.is_account_onboarding,
          }"
        >
          <transition name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </div>
    <div class="flex flex-wrap" v-else>
      <div class="w-full pl-0 min-h-screen">
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SideBar from "./components/SideBar.vue";
import TopBar from "./components/TopBar.vue";
import OnboardingPending from "./components/OnboardingPending.vue";

export default {
  name: "App",
  components: {
    SideBar,
    TopBar,
    OnboardingPending,
  },
  watch: {
    $route() {
      this.showNavigation = false;
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.User.user,
    }),
  },
  data() {
    return {
      showNavigation: false,
    };
  },
  methods: {
    toggleNavigation() {
      console.log('toggleNavigation');
      this.showNavigation = this.showNavigation ? false : true;
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>