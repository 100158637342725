import { createRouter, createWebHistory } from 'vue-router'
import publicRoutes from './public'
import barRoutes from './bar'
import adminRoutes from './admin'
import store from '../store'

const routes = publicRoutes.concat(barRoutes).concat(adminRoutes)
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    store.dispatch('User/getAuth')
      .then((user) => {
        if (user) {
          next()
        } else {
          next({ name: 'Home' })
        }
      }).catch((error) => {
        store.commit('Message/SET_MESSAGE', {
          message: error.message,
          type: 'warning'
        })
        store.commit('User/SET_LOGIN_SUCCESS_ROUTE', to.name)
        next({ name: 'Home' })
      })
  } else if (to.matched.some(route => route.meta.onlyGuest)) {
    store.dispatch('User/setAuthFromLocal')
      .then((user) => {
        if (user.role == 'bar') {
          next({ name: "BrancheList" })
        }if (user.role == 'super_admin') {
          next({ name: "Dashboard" })
        } else {
          next()
        }
      })
  } else {
    if (store.getters['User/isFirstLoad']) {
      store.commit('User/SET_FIRST_LOAD_STATUS', false)
    }
    next()
  }
})

export default router
