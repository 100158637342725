<template>
    <div class="sidebar bg-domLight w-1/2 md:w-1/3 lg:w-64 fixed md:top-0 md:left-0 h-screen lg:block border-r z-30 text-domGray overflow-x-auto"
        :class="{ 'hidden': !showNavigation }">
        <div class="text-xl my-8 px-2 truncate font-bold lg:inline-block hidden w-full text-center">
            {{ user.username ? user.username : user.business_name }}
        </div>
        <div class="mb-4 px-4 text-2xl navigation mt-20 lg:mt-4" v-if="user.role == 'bar'">
            <router-link :to="{ name: 'BrancheList' }">
                <span>Branches</span>
            </router-link>
            <router-link :to="{ name: 'PurchasesList' }">
                <span>Purchases</span>
            </router-link>
            <router-link :to="{ name: 'RedemptionsList' }">
                <span>Redemptions</span>
            </router-link>
            <router-link :to="{ name: 'TransactionsList' }">
                <span>Transactions</span>
            </router-link>
            <!-- <router-link :to="{name: 'BankAccount'}">
                <span>Bank Account</span>
            </router-link> -->
            <router-link :to="{ name: 'AccountDetails' }">
                <span>Account Details</span>
            </router-link>
            <router-link :to="{ name: 'EventList' }">
                <span>Events</span>
            </router-link>
            <div @click="logout()" class="cursor-pointer">
                <span>Log Out</span>
            </div>
        </div>
        <div class="mb-4 px-4 text-2xl navigation mt-20 lg:mt-4" v-if="user.role == 'super_admin'">
            <router-link :to="{ name: 'Dashboard' }">
                <span>Dashboard</span>
            </router-link>
            <router-link :to="{ name: 'BarList' }">
                <span>Bars</span>
            </router-link>
            <router-link :to="{ name: 'UserList' }">
                <span>Users</span>
            </router-link>
            <router-link :to="{ name: 'CategoriesList' }">
                <span>Categories</span>
            </router-link>
            <router-link :to="{ name: 'LookupDrinksList' }">
                <span>Lookup Drinks</span>
            </router-link>
            <router-link :to="{ name: 'ReportsList' }">
                <span>Reports</span>
            </router-link>
            <!-- <router-link :to="{name: 'PagesList'}">
                <span>Pages</span>
            </router-link> -->
            <router-link :to="{ name: 'Settings' }">
                <span>Settings</span>
            </router-link>
            <div @click="logout()" class="cursor-pointer">
                <span>Log Out</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import API from './../api/http'
export default {
    name: 'SideBar',
    props: {
        showNavigation: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState({
            user: state => state.User.user
        })
    },
    methods: {
        logout() {
            if (confirm('Are you sure?')) {
                this.$router.push({ name: 'Home' })
                API.logout().then(() => { })
            }
        },
    }
}
</script>